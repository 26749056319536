import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['jobStatus']

  connect () {
    this.setVisibility()
  }

  getValueFromRadioButtons (inputs) {
    const checkedElement = this.jobStatusTargets.find(element => {
      return element.checked
    })
    if (checkedElement) {
      return checkedElement.value
    }
  }

  jobStatusChanged () {
    this.setVisibility()
  }

  jobStatusValue () {
    return this.getValueFromRadioButtons(this.jobStatusTargets)
  }

  setVisibility () {
    const elements = this.element.querySelectorAll('[data-visible-when-job-status]')
    elements.forEach(element => {
      let result = this.hideEmploymentDetails(element.dataset.visibleWhenJobStatus, this.jobStatusValue())
      element.classList.toggle('hidden', result)
    })
  }

  hideEmploymentDetails (jobStatuses, jobStatusCandidate) {
    let hide = true
    jobStatuses.split(",").forEach(jobStatus => {
      if (jobStatus === jobStatusCandidate) {
        hide = false
      }
    })
    return hide
  }
}

/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

// Make Rails standard helpers (ie data-confirm, data-remote etc) work as
// expected
import Rails from '@rails/ujs'
import { Application } from '@hotwired/stimulus'
import { definitionsFromContext } from '@hotwired/stimulus-webpack-helpers'
import Reveal from 'stimulus-reveal-controller'

window.Stimulus = Application.start()
window.Stimulus.register('reveal', Reveal)
const context = require.context('../controllers', true, /\.js$/)
Stimulus.load(definitionsFromContext(context))

Rails.start()

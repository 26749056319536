import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['jobStatus']

  connect () {
    this.setVisibility()
  }

  getValueFromRadioButtons (inputs) {
    const checkedElement = this.jobStatusTargets.find(element => {
      return element.checked
    })
    if (checkedElement) {
      return checkedElement.value
    }
  }

  jobStatusChanged () {
    this.setVisibility()
  }

  jobStatusValue () {
    return this.getValueFromRadioButtons(this.jobStatusTargets)
  }

  setVisibility () {
    const elements = this.element.querySelectorAll('[data-visible-when-job-status]')
    elements.forEach(element => {
      element.classList.toggle('hidden', element.dataset.visibleWhenJobStatus !== this.jobStatusValue())
    })
  }
}
